import * as React from "react"
import styled from "styled-components"
import { rem } from "../util/style"
import Text from "./Text"

import FooterLogo from "../svgs/footer-logo.svg"

const FooterWrap = styled.div`
  width: 100%;
  position: relative;
  padding: ${props => (props.small ? `${rem(29.41)} 1.5rem` : "0 1.5rem")};
  padding-bottom: ${props => (props.small ? "1.5rem" : rem(86))};
`

const FooterContent = styled.div`
  margin: 0 auto;
  max-width: ${rem(1611)};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const Horizontal = styled.div`
  @media only screen and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: ${rem(16)};
  }
`

const Footer = ({ small }) => (
  <FooterWrap small={small}>
    <FooterContent>
      <Text size="footer" mobile={{ mb: "16", hide: true }}>
        © GuidePad, Inc
      </Text>
      <FooterLogo />

      <Horizontal>
        <Text size="footer" hide mobile={{ show: true }}>
          © GuidePad, Inc
        </Text>
        <a href="mailto:info@guidepad.io" target="_blank" rel="noreferrer">
          <Text size="footer" mobile={{ mr: "24" }}>
            info@guidepad.io
          </Text>
        </a>
      </Horizontal>
    </FooterContent>
  </FooterWrap>
)

export default Footer
