import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Nav from "../components/Navigation"
import Text from "../components/Text"
import Footer from "../components/Footer"
import PricingCard from "../components/Pricing/PricingCard"
import DiscountedPricingCard from "../components/Pricing/DiscountedPricingCard"

import {
  Main,
  Header,
  HeaderContent,
  Plans,
  FooterBG,
} from "../style/pricing"

const PricingPage = () => {
  const { strapiPricingPage } = useStaticQuery(graphql`
    query {
      strapiPricingPage {
        Title
        Subtitle
        Eyebrow
        PricingPlans {
          id
          Type
          Title
          Price
          Frequency
          PlanDescription {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          Features {
            ShowCheckmark
            Title
          }
          ButtonLink
          ButtonContent
          rank
          slug
        }
      }
    }
  `)

  return (
    <Main>
      <Helmet
        bodyAttributes={{ class: "pricing-page" }}
        htmlAttributes={{ class: "pricing-page" }}
      />
      <Nav />
      <Header>
        <HeaderContent>
          <Text size="h5" color="#CAF2FF" mt="96" mb="8" upcase>
            {strapiPricingPage.Eyebrow}
          </Text>
          <Text size="h1" mb="8">
            {strapiPricingPage.Title}
          </Text>
          <Text size="body" color="#9EBCF8" mb="64">
            {strapiPricingPage.Subtitle}
          </Text>
          <Plans>
            {strapiPricingPage.PricingPlans.map(plan => (
              <PricingCard pricingPlan={plan} />
            ))}
          </Plans>
        </HeaderContent>
      </Header>

      <FooterBG>
        <Footer small />
      </FooterBG>
    </Main>
  )
}

export default PricingPage
