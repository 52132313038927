import React from "react"
import styled from "styled-components"
import { rem } from "../../util/style"
import { Link } from "gatsby"
import Text from "../Text"

import CheckmarkIcon from "../../svgs/checkmark.svg"

const CardWrap = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;

  margin: 0 ${rem(10)};
  padding: ${rem(40)} ${rem(40)} ${rem(24)} ${rem(40)};

  background: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }
`

const PlanType = styled.div`
  flex: 0 0 auto;

  margin-bottom: ${rem(14)};

  display: inline-block;
`

const PricingWrap = styled.div`
  margin-bottom: ${rem(20)};
  flex: 0 0 auto;
`
const PlanDescription = styled.p`
  flex: 0 0 auto;
`
const FeatureWrap = styled.div`
  flex: 1 1 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${rem(16)};
`
const CheckWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${rem(8)};
  padding-right: ${rem(17)};
`
const FeatureContent = styled.div`
  flex: 1 1 auto;
`

const Button = styled(props => <Link {...props} />)`
  margin-top: ${rem(12)};
  padding: ${rem(13)} ${rem(21)};

  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #39dec6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`

const Inline = styled.div`
  display: inline-block;
  border-radius: 14px;
  padding: 4px 16px;
  background-color: #e0e7ff;
`

function getFrequency(freq) {
  switch (freq) {
    case "Monthly":
      return "mo"
    case "Yearly":
      return "yr"
    default:
      return "mo"
  }
}

const PricingCard = ({ pricingPlan }) => {
  return (
    <CardWrap>
      <PlanType>
        <Inline>
          <Text size="tag" color="#4F46E5" upcase>
            {pricingPlan.Type}
          </Text>
        </Inline>
      </PlanType>
      <PricingWrap>
        {pricingPlan.Price && pricingPlan.price != "" ? (
          <>
            <Text size="price" color="#000">
              ${pricingPlan.Price}
            </Text>
            <Text size="frequency" color="#6B7280">
              / license /{getFrequency(pricingPlan.Frequency)}
            </Text>
          </>
        ) : (
          <>
            <Text size="price" color="#000">
              Contact Us
            </Text>
          </>
        )}
      </PricingWrap>
      <PlanDescription>
        <Text
          size="body"
          color="#6B7280"
          dangerouslySetInnerHTML={{
            __html: pricingPlan.PlanDescription.data.childMarkdownRemark.html,
          }}
        />
      </PlanDescription>
      {/* <PlanDescription>{pricingPlan.PlanDescription}</PlanDescription> */}
      <FeatureWrap>
        {pricingPlan.Features.map(feature => (
          <Feature>
            <CheckWrap>{feature.ShowCheckmark && <CheckmarkIcon />}</CheckWrap>
            <FeatureContent>
              <Text size="feature" color="#6B7280">
                {feature.Title}
              </Text>
            </FeatureContent>
          </Feature>
        ))}
      </FeatureWrap>
      <Button to={`${pricingPlan.ButtonLink}?plan=${pricingPlan.slug}`}>
        <Text size="buttonm" color="#06122C">
          {pricingPlan.ButtonContent}
        </Text>
      </Button>
    </CardWrap>
  )
}

export default PricingCard
