import React from "react"
import styled from "styled-components"
import { Fonts, Weight } from "../style/fonts"
import { rem } from "../util/style"
import Logo from "../svgs/logo.svg"
import { Link } from "gatsby"

const NavWrap = styled.div`
  padding: ${rem(20.92)} ${rem(55.53)};
  margin-bottom: ${props => (props.mb ? rem(props.mb) : "0")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${props => (props.light ? `#fff` : `#06122c`)};
  z-index: 99;
  position: relative;

  ${props => (props.light ? `svg { path.letter { fill: #06122C } }` : ``)}

  @media only screen and (max-width: 767px) {
    padding: 18px 24px;
  }
`

const LogoLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  max-width: ${rem(254.21)};
  svg {
    max-width: 100%;
  }

  @media only screen and (max-width: 1279px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 767px) {
    max-width: 160px;
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #aaa;
  font-size: ${rem(16)};
  font-family: ${Fonts.inter};
  font-weight: ${Weight.normal};
  line-height: ${rem(24)};
  text-decoration: none;
  margin-left: ${rem(24)};

  transition: all 0.25s linear;

  &:hover {
    color: ${props => (props.light ? `#06122C` : `#fff`)};
  }

  @media only screen and (max-width: 767px) {
    margin-left: 16px;
    font-size: 16px;
  }
`

const Navigation = ({ light, mb }) => {
  return (
    <NavWrap light={light} mb={mb}>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      <Links>
        <StyledLink to="/pricing" light={light}>
          Pricing
        </StyledLink>
        <StyledLink to="/blog" light={light}>
          Blog
        </StyledLink>
      </Links>
    </NavWrap>
  )
}

export default Navigation
