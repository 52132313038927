import styled from "styled-components"
import { rem } from "../util/style"

export const Main = styled.div`
  display: block;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #06122c;

  top: -1px;
  position: relative;

  &::after {
    content: " ";
    height: ${rem(109)};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    position: absolute;
    background-color: #fff;
  }
`

export const HeaderContent = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(1440)};

  @media only screen and (max-width: 1023px) {
    & > h1,
    & > p,
    & > h5 {
      width: 100%;
      padding: 0 2rem;
      text-align: left;
    }
  }

  @media only screen and (max-width: 767px) {
    & > h1,
    & > p,
    & > h5 {
      padding: 0;
      max-width: 460px;
    }
  }
`

export const Plans = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 6rem;
  padding-bottom: 1rem;
  z-index: 5;
  position: relative;

  @media only screen and (max-width: 1023px) {
    padding: 0 1.5rem;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0 0;
  }
`

export const DiscountedPlanWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(54)};
`

export const FooterBG = styled.div`
  background-color: #06122c;
`
