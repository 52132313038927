import React from "react"
import styled from "styled-components"
import { rem } from "../../util/style"
import { Fonts, Weight } from "../../style/fonts"
import { Link } from "gatsby"
import Text from "../Text"

const CardBody = styled.div`
  padding: ${rem(40)};

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 8px;
  background-color: #f3f4f6;
  max-width: 1024px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const PlanType = styled.div`
  margin-bottom: ${rem(14)};

  display: inline-block;

  border-radius: 14px;
  padding: 4px 16px;
  background-color: #e0e7ff;
`

const LeftSide = styled.div`
  flex: 1 1 auto;
`

const RightSide = styled.div`
  flex: 0 0 auto;
  @media only screen and (max-width: 767px) {
    margin-top: ${rem(36)};
  }
`

const PlanTextArea = styled.div`
  p {
    margin: 0;

    color: #6b7280;
    font-size: ${rem(18)};
    font-family: ${Fonts.inter};
    font-weight: ${Weight.normal};
    line-height: ${rem(32)};
    display: inline-block;
  }

  strong {
    display: inline-block;
    color: #111827;
    font-size: ${rem(18)};
    font-family: ${Fonts.inter};
    font-weight: ${Weight.medium};
    line-height: ${rem(28)};
  }
`

const Button = styled.div`
  margin-left: ${rem(40)};
  padding: 13px 21px;

  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`

const DiscountedPricingCard = ({ discountedPlan }) => {
  console.log(discountedPlan)
  return (
    <CardBody>
      <LeftSide>
        <PlanType>
          <Text size="tag" color="#4F46E5" upcase>
            {discountedPlan.Type}
          </Text>
        </PlanType>
        <PlanTextArea
          dangerouslySetInnerHTML={{
            __html: discountedPlan.Content.data.childMarkdownRemark.html,
          }}
        />
      </LeftSide>
      <RightSide>
        <Link
          to={discountedPlan.ButtonUrl}
          style={{
            textDecoration: "none",
          }}
        >
          <Button>
            <Text size="buttonm" color="#06122C">
              {discountedPlan.ButtonContent}
            </Text>
          </Button>
        </Link>
      </RightSide>
    </CardBody>
  )
}

export default DiscountedPricingCard
